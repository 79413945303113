.d-b {
  display: block !important; }

.d-i {
  display: inline !important; }

.d-ib {
  display: inline-block !important;
  max-width: 100%; }

.d-tb {
  display: table !important; }

.d-tbc {
  display: table-cell !important; }

.d-tbr {
  display: table-row !important; }

.vh {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.fl-l {
  float: left !important; }

.fl-r {
  float: right !important; }

.fl-n {
  float: none !important; }

.cl-b {
  clear: both !important; }

.cl-n {
  clear: none !important; }

.cl-l {
  clear: left !important; }

.cl-r {
  clear: right !important; }

.cf:before,
.cf:after {
  content: " ";
  display: table; }

.cf:after {
  clear: both; }

.cf {
  *zoom: 1; }

.rwd-img {
  max-width: 100%;
  height: auto; }

.rwd-img-st {
  width: 100%;
  height: auto; }

.intrinsic {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  text-align: center; }
  .intrinsic div,
  .intrinsic embed,
  .intrinsic object,
  .intrinsic iframe {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; }

.ir {
  text-indent: 110%;
  white-space: nowrap;
  overflow: hidden; }

.pos-r {
  position: relative !important; }

.pos-s {
  position: static !important; }

.pos-a {
  position: absolute !important; }

.pos-f {
  position: fixed !important;
  backface-visibility: hidden; }

.mt-0 {
  margin-top: 0 !important; }

.mt-xxs {
  margin-top: 0.14591em !important; }

.mt-xs {
  margin-top: 0.23608em !important; }

.mt-s {
  margin-top: 0.61805em !important; }

.mt {
  margin-top: 1em !important; }

.mt-l {
  margin-top: 1.618em !important; }

.mt-xl {
  margin-top: 4.2358em !important; }

.mt-xxl {
  margin-top: 6.85353em !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-xxs {
  padding-top: 0.14591em !important; }

.pt-xs {
  padding-top: 0.23608em !important; }

.pt-s {
  padding-top: 0.61805em !important; }

.pt {
  padding-top: 1em !important; }

.pt-l {
  padding-top: 1.618em !important; }

.pt-xl {
  padding-top: 4.2358em !important; }

.pt-xxl {
  padding-top: 6.85353em !important; }

.mr-0 {
  margin-right: 0 !important; }

.mr-xxs {
  margin-right: 0.14591em !important; }

.mr-xs {
  margin-right: 0.23608em !important; }

.mr-s {
  margin-right: 0.61805em !important; }

.mr {
  margin-right: 1em !important; }

.mr-l {
  margin-right: 1.618em !important; }

.mr-xl {
  margin-right: 4.2358em !important; }

.mr-xxl {
  margin-right: 6.85353em !important; }

.pr-0 {
  padding-right: 0 !important; }

.pr-xxs {
  padding-right: 0.14591em !important; }

.pr-xs {
  padding-right: 0.23608em !important; }

.pr-s {
  padding-right: 0.61805em !important; }

.pr {
  padding-right: 1em !important; }

.pr-l {
  padding-right: 1.618em !important; }

.pr-xl {
  padding-right: 4.2358em !important; }

.pr-xxl {
  padding-right: 6.85353em !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-xxs {
  margin-bottom: 0.14591em !important; }

.mb-xs {
  margin-bottom: 0.23608em !important; }

.mb-s {
  margin-bottom: 0.61805em !important; }

.mb {
  margin-bottom: 1em !important; }

.mb-l {
  margin-bottom: 1.618em !important; }

.mb-xl {
  margin-bottom: 4.2358em !important; }

.mb-xxl {
  margin-bottom: 6.85353em !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-xxs {
  padding-bottom: 0.14591em !important; }

.pb-xs {
  padding-bottom: 0.23608em !important; }

.pb-s {
  padding-bottom: 0.61805em !important; }

.pb {
  padding-bottom: 1em !important; }

.pb-l {
  padding-bottom: 1.618em !important; }

.pb-xl {
  padding-bottom: 4.2358em !important; }

.pb-xxl {
  padding-bottom: 6.85353em !important; }

.ml-0 {
  margin-left: 0 !important; }

.ml-xxs {
  margin-left: 0.14591em !important; }

.ml-xs {
  margin-left: 0.23608em !important; }

.ml-s {
  margin-left: 0.61805em !important; }

.ml {
  margin-left: 1em !important; }

.ml-l {
  margin-left: 1.618em !important; }

.ml-xl {
  margin-left: 4.2358em !important; }

.ml-xxl {
  margin-left: 6.85353em !important; }

.pl-0 {
  padding-left: 0 !important; }

.pl-xxs {
  padding-left: 0.14591em !important; }

.pl-xs {
  padding-left: 0.23608em !important; }

.pl-s {
  padding-left: 0.61805em !important; }

.pl {
  padding-left: 1em !important; }

.pl-l {
  padding-left: 1.618em !important; }

.pl-xl {
  padding-left: 4.2358em !important; }

.pl-xxl {
  padding-left: 6.85353em !important; }

.ml-a {
  margin-left: auto !important; }

.mr-a {
  margin-right: auto !important; }

.ta-l {
  text-align: left !important; }

.ta-c {
  text-align: center !important; }

.ta-r {
  text-align: right !important; }

.ta-j {
  text-align: justify !important; }

.c-i {
  color: inherit !important; }

.kern {
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1;
  font-kerning: normal; }

.whs-nw {
  white-space: nowrap !important; }

.truncate {
  max-width: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  word-wrap: normal !important; }

.fw-l {
  font-weight: 200 !important; }

.fw-n {
  font-weight: 400 !important; }

.fw-b {
  font-weight: 700 !important; }

.fs-i {
  font-style: italic !important; }

.tt-u {
  text-transform: uppercase !important; }

.wfsm {
  -webkit-font-smoothing: antialiased; }

.va-t {
  vertical-align: top !important; }

.va-m {
  vertical-align: middle !important; }

.va-b {
  vertical-align: bottom !important; }
